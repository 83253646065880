import { Col, Dropdown, MenuProps, Row, Typography } from "antd"
import { SvgIcon } from "../../../../../shared/icons"
import "./index.scss"

const items: MenuProps["items"] = [
  {
    label: "Option 1",
    key: "0",
  },
  {
    type: "divider",
  },
  {
    label: "Option 2",
    key: "1",
  },
]

export const AccountDropdown = () => {
  return (
    <Dropdown className={"account-dropdown"} menu={{ items }} trigger={["click"]}>
      <Row align={"middle"}>
        <Col>
          <Typography.Paragraph className={"email"}>m@techgrowthkw.com</Typography.Paragraph>
          <Typography.Paragraph className={"name"}>Mohammad Faris</Typography.Paragraph>
        </Col>
        <SvgIcon className={"arrow-icon"} type={"arrowExpand"} width={12} height={12} />
      </Row>
    </Dropdown>
  )
}
